<template>
    <div style="margin:-12px;width:100vw;">
        <top-nav title="Detalles de orden">
            <template v-slot:actions>
                <v-menu bottom left class="rounded-xl" offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon color="white">mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list  dense flat>
                        <v-subheader>Estatus</v-subheader>
                        <v-list-item-group color="primary">
                            <template v-for="status in orderStatuses">
                                <v-list-item :key="status.value" @click="changeStatus(status)" :disabled="order == null || status.value == order.status">
                                    <v-list-item-avatar class="my-0" size="16">
                                        <v-avatar :color="getOrderStatus(status.value)"></v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-title>{{ status.text }}</v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </template>
        </top-nav>

        <div v-if="order != null">
            <v-card flat tile color="grey lighten-5 mb-2">
                <v-card-text>
                    <div class="text-h6 grey--text text--darken-3">#{{ order.orderNumber.toUpperCase() }}</div>
                    <div class="subheading d-flex align-center justify-space-between">
                        {{ formatDate( order.orderDate, 'MMM DD, YYYY @ hh:mm a' ) }}
                        <v-chip label small :color="orderStatusColor" class="white--text mt-1">{{ order.status }}</v-chip>
                    </div>
                </v-card-text>
            </v-card>

            <v-card flat tile color="grey lighten-5 my-2">
                <v-card-text>
                    <v-list color="transparent" subheader>
                        <v-subheader class="pa-0 mb-2" style="height:auto;">Items</v-subheader>
                        <template v-for="prod in order.products">
                                
                            <v-list-item ref="content" class="card-content px-0" :key="prod.productId">
                                <v-badge :content="prod.qty" color="primary" bordered overlap offset-x="25" offset-y="20" left>
                                    <v-list-item-avatar>
                                        <v-avatar>
                                            <v-img :src="getProductImage( prod )"></v-img>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                </v-badge>
                                
                                <v-list-item-content>
                                    <v-list-item-title>{{ prod.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ toMoney( prod.price ) }}</v-list-item-subtitle>
                                </v-list-item-content>
                                
                                <v-list-item-action>
                                    <div>{{ toMoney( prod.total ) }}</div>
                                </v-list-item-action>
                            </v-list-item>
                                
                        </template>
                    </v-list>
                </v-card-text>
            </v-card>

            <v-card flat tile color="grey lighten-5 my-2">
                <v-card-text>
                    <v-row class="mx-2">
                        <v-col cols="6" class="px-0 pb-0 grey--text">Subtotal</v-col>
                        <v-col cols="6" class="text-right px-0 pb-0 grey--text">{{ toMoney( order.subtotal ) }}</v-col>

                        <v-col cols="6" class="px-0 pb-0 grey--text" v-if="couponVisible">Descuentos</v-col>
                        <v-col cols="6" class="text-right px-0 pb-0 grey--text" v-if="couponVisible"> - {{ toMoney( order.discounts ) }}</v-col>

                        <!-- <v-col cols="12" class="text-right px-0 pb-0" v-if="couponVisible">
                            <template v-for="(coupon, ix) in coupons">
                                <v-col cols="2" class="pt-0 pb-1">
                                    <v-btn icon small text color="red" @click="removeCoupon(coupon)"><v-icon small>mdi-close</v-icon></v-btn>
                                </v-col>
                                <v-col cols="6" class="pt-0 pb-1" align-self="center">
                                    <span class="awesome-mini-cart-drawer-text">{{ coupon.code }}</span>
                                </v-col>
                                <v-col cols="4" class="text-right pt-0 pb-1" align-self="center" style="border-right: 2px solid #cdcdcd;">
                                    <span class="awesome-mini-cart-drawer-text">- {{ formatMoney(coupon.amount) }}</span>
                                </v-col>
                            </template>
                        </v-col> -->

                        <v-col cols="6" class="px-0 grey--text">Tax</v-col>
                        <v-col cols="6" class="text-right px-0 grey--text">{{ toMoney( order.tax ) }}</v-col>

                        <v-col cols="6" class="px-0 title grey--text text--darken-2">Total</v-col>
                        <v-col cols="6" class="text-right  px-0 title grey--text text--darken-2">{{ toMoney( order.total ) }}</v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card flat tile color="grey lighten-5 my-2" v-if="order != null">
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <div class="title">Billing</div>
                            <div class="subtitle-1">{{ order.customer.firstName }} {{ order.customer.lastName }}</div>
                            <div>
                                {{ order.billingAddress1 }} <br />
                                {{ order.billingAddress2 }} <br />
                                {{ order.billingCity }} {{ order.billingState }}, {{ order.billingZipcode }}
                            </div>
                        </v-col>
                        <v-col cols="6" v-show="showShippingAddress">
                            <div class="title">Shipping</div>
                            <div class="subtitle-1">{{ order.customer.firstName }} {{ order.customer.lastName }}</div>
                            <div>
                                {{ order.shippingAddress1 }} <br />
                                {{ order.shippingAddress2 }} <br />
                                {{ order.shippingCity }} {{ order.shippingState }}, {{ order.shippingZipcode }}
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card flat tile color="grey lighten-5 my-2">
                <v-card-text>
                    <div class="subtitle-1">Información de pago</div>
                    <div v-if="order.paymentMethod == 'stripe'">
                        <v-list three-line color="transparent">
                            <v-list-item class="px-0">
                                <v-list-item-avatar tile>
                                    <img :src="require(`@/assets/cards/${order.cardType == '' ? 'visa' : order.cardType}.svg`)" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>Tarjeta de crédito</v-list-item-title>
                                    <v-list-item-subtitle>{{ order.paymentConfirmation }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Last 4: {{ order.cardLast4 }} | Exp. {{ order.cardExp }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>

                    <div v-else-if="order.paymentMethod == 'athmovil'">
                        <v-list color="transparent">
                            <v-list-item class="px-0">
                                <v-list-item-avatar tile>
                                    <img src="@/assets/cards/athmovil.svg" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        ATH Móvil
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ order.paymentConfirmation }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>

                    <v-btn block depressed outlined color="primary" class="mt-4" :loading="refunding" @click="showRefundWarning = true"
                        v-if="showRefundButton">Reembolso</v-btn>
                </v-card-text>
            </v-card>

            <v-card flat tile color="grey lighten-5 my-2">
                <v-card-text>
                    <div class="subtitle-1">Notas</div>

                    <div>
                        <template v-for="(note, ix) in notes">
                            <div class="note" :key="ix">
                                <v-row>
                                    <v-col cols="2">
                                        <v-avatar color="primary" class="white--text">
                                            <h4>{{ note.customerInitials }}</h4>
                                        </v-avatar>
                                    </v-col>
                                    <v-col cols="10">
                                        <div class="noteMessage">{{ note.message }}</div>
                                        <div class="noteDate caption text-right">{{ fromNow( note.dateCreated ) }}</div>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>

                        <v-textarea v-model="comment" placeholder="Nuevo comentario" outlined background-color="white" rows="3" hide-details></v-textarea>
                        <v-btn block depressed outlined color="secondary" class="mt-1" @click="saveComment">Guardar comentario</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </div>

        <v-dialog v-model="showRefundWarning">
            <v-card>
                <v-card-text class="pa-3">
                    <div class="body-1 text-center">¿Quieres reembolsar esta orden?</div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="showRefundWarning = false">No</v-btn>
                    <v-btn depressed color="primary" @click="refund">Sí</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="alert.show">
            {{ alert.message }}

            <template v-slot:action="{ attrs }">
                <v-btn color="primary" icon v-bind="attrs" @click="alert.show = false" >
                    <v-icon right>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { _st } from '@/softech';
import { mapGetters } from 'vuex';
import TopNav from '@/components/admin/TopNav';
import { PetuOrder } from '@/classes/order';
import moment from 'moment';
import { PetuProduct } from '@/classes/product';
import { StripeGateway } from "@/classes/gateways/stripe";
import { OrderStatus } from '@/models';
import { PetuCustomer } from '@/classes/customer';
import { AthMovilGateway } from '@/classes/gateways/athmovil'

export default {
    props: {
        orderId: { type: String, default: '' }
    },
    data() {
        return {
            order               : null,

            showRefundWarning   : false,
            refunding           : false,

            orderStatuses       : [ 
                { text: "Processing", value: "processing" },
                { text: "Completed", value: "completed" },
                { text: "Cancelled", value: "cancelled" },
                { text: "Pending Payment", value: "pending payment" },
                { text: "Shipped", value: "shipped" },
                { text: "Failed", value: "failed" },
                { text: "Draft", value: "draft" },
                { text: "Refunded", value: "refunded" } 
            ],
            alert               : {
                show    : false,
                message : '',
            },

            comment             : '',
            notes               : [],
        }
    },
    computed: {
        ...mapGetters({ 
            customer    : 'auth/customer',
        }),
        orderStatusColor() {
            return this.getOrderStatus( this.order.status );
        },
        couponVisible() {
            return this.order.coupons.length > 0;
        },
        stripeConfirmation() {
            if( _st.isNU( this.order ) || this.order.paymentMethod != 'stripe' ) return {};
            return _st.isJson( this.order.paymentConfirmation ) ? JSON.parse( this.order.paymentConfirmation ) : {
                payment_method_details: { card: { brand: '', last4: '', exp_month: '', exp_year: '' } },
                id: ''
            };
        },
        cardImg() {
            return `@/assets/cards/${this.stripeConfirmation.payment_method_details.card.brand.toLowerCase()}.svg`;
        },
        showShippingAddress() {
            return !_st.isNUE( this.order.shippingAddress1 );
        },
        showRefundButton() {
            return this.order.paymentMethod == 'stripe' 
                && this.order.status != OrderStatus.REFUNDED.toLowerCase() 
                && this.order.status != OrderStatus.CANCELLED.toLowerCase();
        }
    },
    async mounted() {
        // load order
        let order = new PetuOrder();

        if( _st.isNUE( this.orderId ) ) {
            this.order = order.data;
            return;
        }

        await order.load( this.orderId );
        this.order = order.data;
        this.notes = order.data.notes;

        for( let note of this.notes ) {
            let cust = new PetuCustomer();
            await cust.load( note.customerID );
            note.customerInitials = cust.getInitials();
        }

        // console.log(this.order);
    },
    methods: {
        formatDate( d, format ) {
            return moment(d).format(format);
        },
        fromNow( d ) {
            return moment(d).fromNow();
        },
        toMoney( v ) {
            return _st.toMoney( v );
        },
        async getProductImage( p ) {
            let prod = new PetuProduct( p );
            return await prod.getProductImage('small');
        },
        async refund() {
            if( this.order.paymentMethod != 'stripe' ) {
                // let athm = new AthMovilGateway();
                // result = await athm.refund({
                //     referenceNumber: this.order.paymentConfirmation,
                //     amount: this.order.total
                // });

                // console.log( result );

                return;
            }

            this.refunding = true;
            this.showRefundWarning = false;

            try {
                let stripe = new StripeGateway({});
                let refund = await stripe.refund({ chargeId: this.order.paymentConfirmation });

                // success - change order status to refunded
                let order = new PetuOrder( JSON.parse( JSON.stringify( this.order ) ) );
                await order.changeStatus( OrderStatus.REFUNDED );

                this.order.status = order.data.status;
            }
            catch(error) {
                console.log( error );
                this.alert.message = error;
                this.alert.show = true;
                console.error( 'Hubo un error procesando el reembolso.' );
            }
            finally {
                this.refunding = false;
            }
        },
        async changeStatus( status ) {
            try {
                let order = new PetuOrder( JSON.parse( JSON.stringify( this.order ) ) );
                await order.changeStatus( status.value );

                this.order.status = order.data.status;
            }
            catch(error) {
                console.log( error );
                console.error( 'Hubo un error cambiando el estatus de la orden.' );
            }
        },
        getOrderStatus( status ) {
            switch( status ) {
                case 'processing'       : return 'orange';
                case 'completed'        : return 'green';
                case 'cancelled'        : return 'blue-grey';
                case 'pending payment'   : return 'amber';
                case 'shipped'          : return 'teal';
                case 'failed'           : return 'red';
                case 'draft'            : return 'grey';
                case 'refunded'         : return 'purple';
            }
        },
        async saveComment() {
            try {
                // this.notes.push( { customerId: this.customer.id, customer: this.customer, message: this.comment, dateCreated: moment() } );
                // this.comment = '';

                // let tmpOrder = JSON.parse( JSON.stringify( this.order ) );

                // tmpOrder.notes = this.notes;
                let order = new PetuOrder( Object.assign({}, this.order ) );

                await order.addNote( this.comment );

                // await order.save();
                // await order.load( this.order.id );
                this.order = order.data;
                this.comment = '';
            }
            catch(error) {
                console.log(error);
            }
        },
    },
    components: {
        TopNav
    }    
}
</script>

<style lang="scss" scoped>
.note {
    background-color: #ececec;
    border-radius: 10px;
    padding: 8px;
    margin: 8px 0;
}
</style>